<template>
  <div class="page-content">
    <div class="header-box mb-24">
      <div class="left">
        <span>岗位：</span>
        <el-select class="mr-20" v-model="queryParams.JobId" clearable>
          <el-option
            v-for="item in jobList"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          ></el-option>
        </el-select>
        <el-button type="primary" @click="initialize">搜索</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      v-loading="loading"
      height="640px"
    >
      <template v-for="(item, index) in tableConfig">
        <el-table-column :key="index" :label="item.label" :prop="item.prop">
          <template slot-scope="scope">
            <div v-if="item.prop === 'Date'">
              <span>{{ formartDate(scope.row.StartTime, "day") }}</span>
              <span> 至 </span>
              <span>{{ formartDate(scope.row.EndTime, "day") }}</span>
            </div>
            <span v-else-if="item.prop === 'PayDate'">{{
              formartDate(scope.row.PayDate, "day")
            }}</span>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
import { getPageList } from "@/api/salaryRecord";
import { queryApplyJobCatelog } from "@/api/jobApply";
import contentTools from "@/mixins/content-tools";
import { StaffTypeEnum } from "@/utils/constant";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["employee"]),
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    }
  },
  mixins: [contentTools],
  data() {
    return {
      loading: false,
      dialogVisible: false,
      queryParams: {
        PageSize: 999,
        PageIndex: 1
      },
      tableData: [],
      tableConfig: [
        {
          label: "学生姓名",
          prop: "UserName"
        },
        {
          label: "岗位名称",
          prop: "JobName"
        },
        {
          label: "协议薪资",
          prop: "DealSalary"
        },
        {
          label: "薪资对应日期",
          prop: "SalaryTime"
        },
        {
          label: "实际发放薪资金额",
          prop: "Value"
        },
        {
          label: "实际发放日期",
          prop: "PayDate"
        }
      ],
      jobList: []
    };
  },
  async created() {
    await this.initialize();
    await this.getJobList();
  },
  methods: {
    async initialize() {
      try {
        if (this.RoleType === StaffTypeEnum.School) {
          let temp = this.employee.find(el => el.StaffType === this.RoleType);
          this.queryParams.SchoolId = temp.Employer.Id;
        } else if (this.RoleType === StaffTypeEnum.Enterprise) {
          let temp = this.employee.find(el => el.StaffType === this.RoleType);
          this.queryParams.EnterpriseId = temp.Employer.Id;
        } else {
          return null;
        }
        this.loading = true;
        await this.getTableData();
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
    async getTableData() {
      const res = await getPageList(this.queryParams);
      if (res.IsSuccess) {
        this.tableData = res.Result.Data;
      }
    },
    async getJobList() {
      const res = await queryApplyJobCatelog({
        schoolId: this.queryParams.SchoolId
      });
      if (res.IsSuccess) {
        this.jobList = res.Result;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header-box {
  display: flex;
  justify-content: space-between;
}
.table {
  overflow-x: auto;
}
</style>
